import React from "react";
import { Link } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'


function getIcon() {
  return (<FontAwesomeIcon icon={faUser} className="mr-1"></FontAwesomeIcon>)
}

function getColor() {
  return 'text-white bg-pink-800 hover:bg-pink-900 border border-pink-800 hover:border-pink-900'
}

/**
 * Render a block representing a single topic.
 * @param {object} props Properties object containing a topic field with a topic ID.
 * @returns React element containing a post.
 */
function PresenterChip(props) {  
  const icon = getIcon(props)
  const color = getColor(props)

  return (
    <Link to={`/presenter/${props.presenter.presenterId}`} itemProp="url">
      <div className={`flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full hover:shadow-md ${color}`}>
        <div slot="avatar">
          {icon}
        </div>
        <div className="text-sm font-normal leading-none max-w-full flex-initial">
          {props.presenter.name}
        </div>
        {
          props.count ?
            <span className="inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-bold leading-none text-gray-200 bg-blue-800 rounded-full">{props.count}</span>
            : ''
        }
      </div>
    </Link>
  );
}

export default PresenterChip;