import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PresenterChip from "../components/presenter-chip"

const PresenterIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const presenters = data.presenters.nodes
    .map(node => node.childMarkdownRemark.frontmatter)
    .sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}))

  // Collate the posts by presenter.
  const postsByPresenter = new Map()
  for (const post of data.posts.nodes) {
    if (post.childMarkdownRemark.frontmatter.presenters) {
      for (const presenter of post.childMarkdownRemark.frontmatter.presenters) {
        const presenterId = presenter.frontmatter.presenterId
  
        if (postsByPresenter.has(presenterId)) {
          postsByPresenter.set(presenterId, postsByPresenter.get(presenterId) + 1)
        } else {
          postsByPresenter.set(presenterId, 1)
        }
      }
    }
  }

  // Group topics by first letter.
  let presenterGroups = presenters.reduce((r, e) => {
    // get first letter of name of current element
    let group = e.name[0].toUpperCase()

    // if there is no property in accumulator with this letter create it
    if(!r[group]) r[group] = {group, children: [e]}
    
    // if there is push current element to children array for that letter
    else r[group].children.push(e);
    
    // return accumulator
    return r;
  }, {})

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Presenters" />

      <h1 className="text-center mt-2 mb-4">Presenter index</h1>
      
      <ul style={{ listStyle: `none` }}>
        {Object.values(presenterGroups).map(group => {
          return (<li><h2 className="mt-4">{group.group}</h2>
            {group.children.map(presenter => {
        
              return (
                <li key={presenter.fieldValue} className="mb-0">
                  <div className="flex flex-wrap justify-left">
                    <PresenterChip presenter={presenter} count={postsByPresenter.get(presenter.presenterId)}/>
                  </div>
                </li>
              )
            })}
          </li>)
        })}
      </ul>
    </Layout>
  )
}

export default PresenterIndex

export const pageQuery = graphql`
  query PresenterIndex {
    site {
      siteMetadata {
        title
      }
    }
    presenters: allFile(
      filter: {sourceInstanceName: {eq: "presenters"}}
      sort: {fields: [childrenMarkdownRemark___frontmatter___name], order: ASC}
    ) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            name
            type
            presenterId
          }
        }
      }
    }
    posts: allFile(filter: { sourceInstanceName: { eq: "posts"} }, sort: { fields: [childrenMarkdownRemark___frontmatter___date], order:DESC}) {
      nodes {
        childMarkdownRemark {
          id
          fields {
            slug
          }
          frontmatter {
            presenters {
              frontmatter {
                presenterId
              }
            }
          }
        }
      }
    }
  }
`
